import Vue from "vue";

/*TITLE*/
document.title = "Pinar de Garrucha Fase III | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Pinar de Garrucha Fase III";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Pinar de Garrucha Fase III";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "1-livingroom--20221124091509.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091509.jpeg";
Vue.prototype.$image_bath1 = "1-bathroom1--20221124091509.jpeg";
Vue.prototype.$image_bath2 = "1-bathroom2--20221124091509.jpeg";
Vue.prototype.$image_room = "0-bedroom--20221124091509.jpeg";
Vue.prototype.$image_facade = "1--20230126120103.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "1-livingroom--20221124091509.jpeg",
  },
  {
    src: "salon_vela-smoked_596x596--20230126120117.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091509.jpeg",
  },
  {
    src: "1-kitchen--20221124091509.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "1-bathroom1--20221124091509.jpeg",
  },
  {
    src: "bp_vela-natural-vela-natural_316x90-limit-vela-natural_316x90--20230126120135.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "1-bathroom2--20221124091509.jpeg",
  },
  {
    src: "bsec_matika-bone_602x602-matika-bone_316x90-stripe-matika-bone_316x90--20230126120144.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "0-bedroom--20221124091509.jpeg",
  },
  {
    src: "1-bedroom--20221124091509.jpeg",
  },
  {
    src: "2-bedroom--20221124091509.jpeg",
  },
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "1--20230126120103.jpg",
  },
  {
    src: "2--20230126120113.jpg",
  },
  {
    src: "3--20230126120119.jpg",
  },
  {
    src: "4--20230126120125.jpg",
  },
  {
    src: "5--20230126120132.jpg",
  },
  {
    src: "7--20230126120138.jpg",
  },
  {
    src: "8--20230126120144.jpg",
  },
  {
    src: "9--20230126120151.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20murillo%20pinar%20de%20garrucha%20bajo%20garrucha%20almería%2004630",
    text: "Calle Murillo (Pinar De Garrucha), Bajo, Garrucha , Almería, 04630",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carretera%20vera%20garrucha%20urbanización%20buganvilla%20local%2019%2020%2004620",
    text: "Carretera Vera - Garrucha, Urbanización Buganvilla. Local 19-20, 04620",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:600433598",
    text: "600433598",
  },
  {
    icon: "mdi-email",
    link: "mailto:clientes@cci10.com",
    text: "clientes@cci10.com",
  },
];
